import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import {
    setSelectedOrder,
    setSelectedOrderTab,
    setSelectedOrderView,
    setCachedOrdersFromAllOrders,
    setSelectedRepair,
    setSelectedRga,
} from 'reducers/orders';
import MultiView, {ViewType} from 'components/MultiView';
import withViewToggle from 'components/OrdersHOC';
import PropTypes from 'prop-types';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LegendSpan = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-weight: '100';
    margin-left: 5px;
    padding-right: 20px;
`;

const LegendWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

const UrgentLegend = styled.div`
    background-color: #fbcbcb;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const LegendTitle = styled.span` 
    font-weight: 600;
    font-size: 14px;
`;

const OrdersHistory = (props) => {
    OrdersHistory.gridName = 'ordersHistory';
    const {isMobile} = props;
    const gridName = 'ordersHistory';
    const history = useHistory();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setGridSelectedOrder] = useState(null);
    const [filters, setFilters] = useState([]);
    const [oems, setOems] = useState([]);
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const showOrders = (menuSettings?.find(c => c.id === '123' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showRepairs = (menuSettings?.find(c => c.id === '124' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const cachedOrdersFromAllOrders = useSelector(state => state.orders.cachedOrdersFromAllOrders);
    const showReturnsAndExchanges = (menuSettings?.find(c => c.id === '125' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showLoanersAndRentals = (menuSettings?.find(c => c.id === '134' && c.name === 'Y') && facilities.length > 0) ? true : false;

    const dispatch = useDispatch();

    if (selectedOrder != null) {
        handleSelectedOrder();
    }

    useEffect(() => {
        document.querySelector('html').scrollTop = 0;
    }, []);

    useEffect(() => {
        getData();
    }, [filters]);

    const buildFiltersRequest = () => {
        let f = [...filters];

        return {
            'start': 0,
            'limit': 10000,
            'terms': f,
        }
    }

    const getData = async () => {
        setLoading(true);

        if (filters && filters.length === 0) return;

        getGridPreferences();
        const filtersRequest = buildFiltersRequest();

        const response = await axios.post(`order/search`, filtersRequest);
        const d = removeData(response.data);

        setData(d);
        dispatch(setCachedOrdersFromAllOrders(response.data));
        setLoading(false);
    }

    const removeData = (d) => d.filter(x => (showOrders && x.statusType === 'ORDER')
        || (showRepairs && x.statusType === 'REPAIR')
        || (showReturnsAndExchanges && x.statusType === 'EXCHANGERETURN')
        || (showLoanersAndRentals && x.statusType === 'LAONERSRENTALS'));

    const getCachedData = async () => {
        if (cachedOrdersFromAllOrders.length === 0) {
            getData();
        } else {
            setData(cachedOrdersFromAllOrders);
            setLoading(false);
        }
    }

    const setColumnList = (columnList) => {
        setColumns(columnList);
    }

    const handleSelect = (order) => {
        let currentOrder = {...selectedOrder};
        currentOrder = order;
        setGridSelectedOrder(currentOrder);
    }

    function handleSelectedOrder() {
        if (location.search != null) 
            dispatch(setSelectedOrderTab(location.search.split('=')[1]));

        const orderDetailsUrl = selectedOrder.data.displayPo 
            ? `/order-details/po/${selectedOrder.data.displayPo}` 
            : `/order-details/${selectedOrder.data.orderNumber}}`;
        
        const repairDetailsUrl = selectedOrder.data.displayPo 
            ? `/repair-details/PO/${selectedOrder.data.displayPo}` 
            : `/repair-details/${selectedOrder.data.orderNumber}}`;

        if (selectedOrder.data !== undefined) {
            switch (selectedOrder.data.statusType) {
                case 'ORDER':
                    dispatch(setSelectedOrder(selectedOrder.data, {
                        firstBreadcrumb: 'All Orders', secondBreadcrumb: 'Order', returnText: 'All Orders',
                    }));
                    history.push(orderDetailsUrl);
                    break;
                case 'REPAIR':
                    dispatch(setSelectedRepair(selectedOrder.data, {
                        firstBreadcrumb: 'All Orders', secondBreadcrumb: 'Repair', returnText: 'All Orders',
                    }));
                    history.push(repairDetailsUrl);
                    break;
                case 'EXCHANGERETURN':
                    dispatch(setSelectedRga(selectedOrder.data, {
                        firstBreadcrumb: 'All Orders', secondBreadcrumb: 'Exchange & Return', returnText: 'All Orders',
                    }));
                    history.push(`/exchange-return-details/${selectedOrder.data.orderNumber}`);
                    break;
            }

        } else {
            dispatch(setSelectedOrder(selectedOrder));
            history.push(orderDetailsUrl);
        }
    }

    const getGridPreferences = async () => {
        const response = await axios.get(`setting/grid/${gridName}`);
        setColumns(response.data.columns);
        dispatch(setSelectedOrderView(response.data.view));
    }

    const renderLegend = () => {
        return (
            <LegendWrapper>
                <LegendTitle>Legend:</LegendTitle>
                <UrgentLegend />
                <LegendSpan>Critical Items</LegendSpan>
            </LegendWrapper>
        );
    }

    const renderRow = (rowData) => {
        const isUrgent = rowData.urgencyDescription === 'CHD' || rowData.urgencyDescription === 'STAT';
        return {
            gridhighlightedRow: isUrgent,
        };
    }

    const saveGridPreferences = (grid) => {
        dispatch(setSelectedOrderView(grid.view));
    }

    const onApply = async (f) => {
        setLoading(true);
        setFilters(f);
    }

    const getOems = async (query) => {
        const response = await axios.get(`/company/oems${query ? 'query=' + query : ''}`);
        setOems(response.data);
    }

    return (
        <Container>
            <MultiView
                gridName={gridName}
                label="All Orders"
                view={isMobile ? ViewType.Card : ViewType.Grid}
                selectionMode="single"
                data={data}
                disableCardView={true}
                onRenderLegend={renderLegend}
                onRenderRow={renderRow}
                onSaveGridPreferences={saveGridPreferences}
                loadingData={loading}
                setLoadingData={setLoading}
                columns={columns}
                setColumnList={setColumnList}
                onRefresh={getCachedData}
                onSelect={handleSelect}
                showFilters={true}
                getOems={getOems}
                oems={oems}
                filters={filters}
                onApply={(e) => onApply(e)}
                isMobile={isMobile} />
        </Container>
    );
};

export default withViewToggle(OrdersHistory);

OrdersHistory.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    gridName: PropTypes.string.isRequired
};
