import React, {useEffect, useState} from 'react';
import {ViewType} from 'components/MultiView';

const withViewToggle = Component => ({...props}) => {
    const isMobileRef = React.useRef(false);
    const isPhoneRef = React.useRef(false);
    const viewRef = React.useRef(true);
    const [view, setView] = useState(ViewType.Card);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1149 ? true : false);
    const [isPhone, setIsPhone] = useState(window.innerWidth < 820 ? true : false);

    useEffect(() => {
        document.querySelector('html').scrollTop = 0;
        isMobileRef.current = window.innerWidth <= 1149 ? true : false;

        function toggleView() {
            if (window.innerWidth <= 1149 && isMobileRef.current === true) {
                if (Component.gridName !== 'ordersHistory') {
                    setView(ViewType.Card);
                    isMobileRef.current = false;
                } else {
                    setView(ViewType.Grid);
                    isMobileRef.current = true;    
                }
            } else if (window.innerWidth > 1149 && isMobileRef.current === false && viewRef.current === ViewType.Grid) {
                setView(ViewType.Grid);
                isMobileRef.current = true;
            } else if (window.innerWidth > 1149 && isMobileRef.current === false) {
                isMobileRef.current = true;
            }

            if (window.innerWidth <= 1149 && isMobileRef.current === false) {
                if (Component.gridName !== 'ordersHistory') {
                    setIsMobile(true);
                }
            } else if (window.innerWidth > 1149 && isMobileRef.current === true)
                setIsMobile(false)

            if (window.innerWidth <= 820 && isPhoneRef.current === false) {
                setIsPhone(true);
                isPhoneRef.current = true;
            } else if (window.innerWidth > 820 && isPhoneRef.current === true) {
                setIsPhone(false)
                isPhoneRef.current = false;
            }
        }
        window.addEventListener('resize', toggleView);
        return () => {
            window.removeEventListener('resize', toggleView);
        }
    }, []);

    useEffect(() => {
        setIsMobile(Component.gridName !== 'ordersHistory' ? window.innerWidth <= 1149 ? true : false : false);
    },[Component.gridName]);

    const setViewRef = (value) => {
        viewRef.current = value;
    }

    return (
        <>
            <Component isMobile={isMobile} isPhone={isPhone} view={view} setViewRef={setViewRef} setView={setView} {...props} />
        </>
    );
};

export default withViewToggle;
