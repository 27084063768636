import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, theme} from '@partssourceinc/react-ui-core';

const Banner = styled.div`
    position: fixed;
    bottom: 10px;
    background-color: #FFE4C0;
    text-align: center;
    min-height: 65px;
    margin: 0 auto;
    z-index: 20000;
    box-shadow: 0 0 10px rgba(0,91,166,0.5);

    @media screen and (min-width: ${theme.screenXLMin}) {
        max-width: 1270px;
        width: 1270px;
    }
    @media screen and (max-width: ${theme.screenLGMax}) {
        max-width: 871px;
        width: 871px;
    }
    @media screen and (max-width: ${theme.screenMDMax}) {
        max-width: 720px;
        width: 720px;
    }
    @media screen and (max-width: ${theme.screenSMMax}) {
        max-width: 490px;
        width: 490px;
    }
    @media screen and (max-width: ${theme.screenXsMax}) {
        max-width: 400px;
        width: 90%;
    }
    @media screen and (max-width: 320px) {
        max-width: 280px;
        width: 280px;
    }
`;

const NewExclamation = styled.span`
    background: url('/images/icn_orange-exclamation@2x.png');
    background-size: 30px;
    margin-right: 30px;
    vertical-align: middle;
    position:relative;
    top: 17px;
    height: 30px;
    width: 30px;
    display: block;
    @media screen and (max-width: 820px) {
        display: none;
    }
`;

const IconColumn = styled.div`
    float: left;  
    width:5%; 
    min-width: 40px;
    @media screen and (max-width: ${theme.screenSMMax}) {
        min-width: 0px;
    }
    height: 100%; 
    padding-right: 20px;
    display: block;
`;

const MessageContainer = styled.span`
    font-family: "Source Sans Pro", sans-serif;
    line-height: 20px;
    font-weight: 600;
    margin: 0 !important;
    font-size: 16px;
    text-align: left;
    display: block;
    width: 95%;
    padding-top: 7px;
    padding-bottom: 7px;
    @media screen and (max-width: ${theme.screenSMMax}) {
        font-size: 14px;
        text-aling: justify;
        width: 100% !important;

    }
`;

const ButtonColumn = styled.div`
    float: right;
    height: 100%; 
    padding-bottom: 15px;
    @media screen and (max-width: 820px) {
        width: 20%;
        float: right !important;
        margin-right: 46px;
    }
`;

const AcceptButton = styled(Button)`
    top: 10px;
    min-width: 100px;
`;

const StyledAnchor = styled.a`
    color: #777777;
    font-weight: normal;
    text-decoration: underline;
`;

const CustomBootstrapContentColumn = styled.div`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    min-height: 1px;
    padding-top: 24px;
    padding-bottom: 24px;

    @media screen and (min-width: ${theme.screenMDMin}) {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    @media screen and (max-width: ${theme.screenSMMax}) {
        width: 100%;
        max-width: 100%;
        padding-left: 0px;
        paddin-right: 0px;
        padding-top: 16px;
        padding-bottom: 5px;
    }

    @media screen and (max-width: ${theme.screenSMMin}) {
        padding-bottom: 15px;
    }
`;

const CustomBootStrapButtonColumn = styled.div`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    min-height: 1px;
    padding-top: 24px;
    padding-bottom: 24px;

    @media screen and (min-width: ${theme.screenMDMin}) {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    @media screen and (max-width: ${theme.screenSMMax}) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding-top: 0px;
        padding-bottom: 15px;
    }
`;

const CookieBannerContent = ({
    /** called when user accepts cookies */
    onAccept,
    isLoggedIn,
    cookiesPolicyAccepted,
}) => {
    const [reachedBottom, setReachedBottom] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                setReachedBottom(true);
            } else {
                setReachedBottom(false);
            }
        });
    }, [])

    const isRC = window.location.host.startsWith('rc');

    const bottom = {
        transition: 'all 0.3s linear',
        transform: `translateY(${!isRC ? '-70px' : '-115px'})`,
    }
    const notBottom = {
        transition: 'all 0.3s linear',
        transform: 'translateY(0px)',
    }

    return (
        <Banner className="row" style={reachedBottom ? bottom : notBottom}>
            <CustomBootstrapContentColumn>
                <IconColumn>
                    <NewExclamation />
                </IconColumn>
                <MessageContainer>
                    {
                        (!isLoggedIn || (isLoggedIn && !cookiesPolicyAccepted)) &&
                        <span><b>Cookies Policy:</b> We use cookies necessary for the functioning of our website and to enhance your experience.
                            To learn more about our cookies, please read our &nbsp;
                        <StyledAnchor href={`${process.env.REACT_APP_PARTSSOURCE_URL}/policies/cookiespolicy`} 
                            target="_blank" rel="noopener noreferrer">cookies policy</StyledAnchor>.
                            By clicking &quot;Accept&quot;, you agree to our use of cookies and similar technology.<br /></span>
                    }
                </MessageContainer>
            </CustomBootstrapContentColumn>
            <CustomBootStrapButtonColumn>
                <ButtonColumn>
                    <AcceptButton onClick={onAccept}>ACCEPT</AcceptButton>
                </ButtonColumn>
            </CustomBootStrapButtonColumn>
        </Banner>
    );
}

CookieBannerContent.propTypes = {
    onAccept: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    cookiesPolicyAccepted: PropTypes.bool.isRequired,
};

export default CookieBannerContent;
